import { Link } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import * as React from 'react';

import { Logo, SEO } from '../components';
import { Facebook, Instagram } from '../components/vectors';
import { useGraphQL } from '../hooks';

export default function SuccessPage() {
  const { marbleBg, site } = useGraphQL();

  return (
    <div className="relative font-sans text-gray-700">
      <SEO title="Success" />
      <GatsbyImage
        image={marbleBg.childImageSharp.gatsbyImageData}
        className="h-screen opacity-75"
        alt=""
      />
      <div className="absolute inset-0 flex flex-col items-center justify-center">
        <Link className="w-full max-w-md px-8" to="/">
          <h1>
            <Logo className="w-full fill-current" />
          </h1>
        </Link>
        <div className="text-center">
          <h2 className="mt-12 font-serif text-xl leading-none uppercase sm:text-3xl oldstyle-nums">
            Form submitted successfully!
          </h2>
          <p className="mt-2">
            Thank you for your message. We will get back to you as soon as we
            can.
          </p>
        </div>
        <p className="mt-12">
          <Link
            to="/"
            className="inline-block px-4 py-1 leading-none uppercase transition-all border hover:bg-gray-800 hover:border-gray-800 hover:text-white"
          >
            Home
          </Link>
        </p>
        <p className="flex flex-wrap items-center justify-center mt-12">
          <a
            className="my-2"
            href={site.siteMetadata.facebook}
            rel="noopener noreferrer"
            target="_blank"
          >
            <Facebook className="w-6 h-6 mr-2 fill-current hover:text-gray-600" />{' '}
          </a>
          <a
            className="my-2"
            href={site.siteMetadata.instagram}
            rel="noopener noreferrer"
            target="_blank"
          >
            <Instagram className="w-6 h-6 mr-2 fill-current hover:text-gray-600" />
          </a>
          <span className="flex-shrink-0 font-serif uppercase">
            Follow us on social media
          </span>
        </p>
      </div>
    </div>
  );
}
